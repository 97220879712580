<template>
  <component
    :class="`${
      invert ? 'text-white' : 'text-primary'
    } text-[1.875rem] font-bold leading-[2.5rem]`"
    v-bind:is="heading"
  >
    <span class="bg-secondary mr-3 inline-block h-3 w-3 rounded-full"></span>
    <slot></slot>
    <span class="bg-ternary ml-3 inline-block h-3 w-3 rounded-full"></span>
  </component>
</template>

<script>
export default {
  props: {
    heading: {
      type: String,
      default: "h1",
    },
    invert: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
